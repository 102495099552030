<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">订单基本信息
      </el-col>
      <el-button type="primary" style="text-algin:right" @click="save()">保存</el-button>
    </el-row>
    <el-form ref="orderForm" :model="orderForm" :rules="rules" label-width="130px">
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="采购单号" prop="outboundPairs">
            <el-input v-model="orderForm.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :class="$i18n.locale" label="合同号">
            <el-input v-model="orderForm.contractCode" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="数量" prop="totalNumber">
            <el-input v-model="orderForm.totalNumber" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :class="$i18n.locale" label="采购金额" prop="totalPrice">
            <el-input v-model="orderForm.purchaseTotalPrice" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="创建日期" prop="createTime">
            <el-input v-model="orderForm.createTime" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="创建人" prop="createByName">
            <el-input v-model="orderForm.createByName" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="供应商交期反馈" prop="vendorDeliveryFeedback">
            <el-input v-model="orderForm.vendorDeliveryFeedback" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="预计交货日期" prop="predictDeliveryDate">
            <el-input v-model="orderForm.predictDeliveryDate" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="采购员" prop="purchasePersonnel">
            <el-input v-model="orderForm.purchasePersonnel" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="订单标签">
            <el-select v-model="orderForm.purchaseOrderTagList" clearable multiple :placeholder="$t('page.selectPlaceholder')" style="width:100%;" @change="getTag()">
              <el-option
                v-for="item in _getAllCommodityDict('SCM_PURCHASE_ORDER_TAG')"
                :key="item.val"
                :label="item.label"
                :value="item.val"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="备注" prop="remark" :rules="{required: isRemark, message: this.$t('page.required'), trigger: 'blur' }">
            <el-input
              v-model="orderForm.remark"
              type="textarea"
              :placeholder="$t('page.inputPlaceholder')"
              maxlength="2000"
              show-word-limit
            />
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row class="grid-content bg-purple-dark">
        <el-col :span="20" class="bg-purple-title">买方信息</el-col>
      </el-row>
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item :class="$i18n.locale" label="买方" prop="buyerCompany">
            <el-select
              v-model="buyerCompany"
              value-key="id"
              :placeholder="$t('page.selectPlaceholder')"
              @change="handleChangeCode"
            >
              <el-option v-for="item in DetailsDatas" :key="item.id" :label="item.companyName" :value="item" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :class="$i18n.locale" label="买方联系人">
            <el-input v-model="orderForm.buyerUser" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="买方电话" prop="site">
            <el-input v-model="orderForm.buyerPhone" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="grid-content bg-purple-dark">
        <el-col :span="20" class="bg-purple-title">卖方信息</el-col>
      </el-row>
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item :class="$i18n.locale" label="卖方" prop="sellerCompany">
            <el-input v-model="orderForm.sellerCompany" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :class="$i18n.locale" label="卖方联系人" prop="sellerUser">
            <el-input v-model="orderForm.sellerUser" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="卖方电话" prop="sellerPhone">
            <el-input v-model="orderForm.sellerPhone" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
      </el-row> -->
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">订单明细</el-col>
    </el-row>
    <el-table
      ref="addOutboundTable"
      class="mb-3"
      :data="tabledatas"
      :header-cell-style="{background:'#fafafa'}"
      max-height="600px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="imgUrl" label="图片" width="120" align="center" sortable>
        <template slot-scope="scope">
          <img :src="scope.row.imgUrl" alt="暂无图片" style="width: 50px;height: 50px">
        </template>
      </el-table-column>
      <el-table-column prop="specification" label="规格型号" />
      <el-table-column prop="stylePartnerCode" label="Style Partner Code" width="150" />
      <el-table-column prop="productName" label="Style Name" width="120" />
      <el-table-column prop="sku" label="SKU" align="center" />
      <el-table-column
        prop="skuPartnerCode"
        label="SKU Partner Code"
        width="150"
      />
      <el-table-column prop="fnsku" label="FNSKU" align="center" />
      <el-table-column prop="upc" label="UPC" width="120" align="center" />
      <el-table-column prop="vendorName" label="供应商" width="100" align="center" />
      <el-table-column prop="shoeBatchNo" label="鞋印批次号" width="100" align="center" />
      <el-table-column prop="predictDeliveryDate" label="预计交货日期" width="110" align="center" />
      <el-table-column prop="stockChannelName" label="备货渠道" width="110" align="center" />
      <!-- <el-table-column prop="paymentPrice" label="已付款金额" width="100" align="center" /> -->
      <el-table-column prop="platform" label="平台" width="100" align="center" />
      <el-table-column prop="site" label="站点" width="100" align="center" />
      <el-table-column prop="cargoType" label="双/箱" width="90" align="center" />
      <el-table-column prop="number" label="数量" width="90" align="center" />
      <el-table-column prop="returnCollectNumber" label="返修收货数量" width="140" align="center" sortable />
      <el-table-column prop="collectNumber" label="收货数量" width="100" align="center" sortable />

      <el-table-column prop="currencyType" label="币种" width="120" align="center" sortable>
        <template slot-scope="scope">
          {{ getAuditTypeLabel('CURRENCY_TYPE',scope.row.currencyType) }}
        </template>
      </el-table-column>
      <el-table-column prop="purchasePrice" label="采购单价" width="100" align="center" />
      <el-table-column prop="purchaseTotalPrice" label="采购金额" width="100" align="center" />
      <template>
        <el-table-column v-if="showLabel" prop="changeAfterHaveTaxPrice" label="调整后单价（含税）" align="center" min-width="150" />
        <el-table-column v-if="showLabel" prop="changeTotalPrice" label="调整后金额" align="center" min-width="90" />
      </template>
      <el-table-column prop="brand" label="品牌" width="100" align="center" />
      <el-table-column prop="seasonI18" label="季节" width="90" align="center" />

      <el-table-column prop="category2Code" label="二级类别" width="100" align="center" />
      <el-table-column prop="category3Code" label="三级类别" width="100" align="center" />
      <el-table-column min-width="120" label="材料">
        <template v-if="scope.row.myList" slot-scope="scope">
          <div v-for="(item, index) in scope.row.myList" :key="index">
            <div v-if="item.value">{{ item.name }}：{{ item.value }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="flockingSkinGraftingName" label="植绒/植皮" width="100" align="center" />
      <el-table-column prop="packageCode" label="包装代码" width="100" align="center" />
      <el-table-column prop="shoeFoldFlag" label="鞋是否折叠" align="center" width="100" />
      <el-table-column width="130" label="外箱尺寸（cm）">
        <template slot-scope="scope">
          <div v-if="scope.row.outerBoxLength">长：{{ scope.row.outerBoxLength }}</div>
          <div v-if="scope.row.outerBoxWidth">宽：{{ scope.row.outerBoxWidth }}</div>
          <div v-if="scope.row.outerBoxHeight">高：{{ scope.row.outerBoxHeight }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="outerBoxWeight" label="外箱毛重（kg）" align="center" width="130" />
      <el-table-column width="130" label="鞋盒尺寸（cm）">
        <template slot-scope="scope">
          <div v-if="scope.row.innerBoxLength">长：{{ scope.row.innerBoxLength }}</div>
          <div v-if="scope.row.innerBoxWidth">宽：{{ scope.row.innerBoxWidth }}</div>
          <div v-if="scope.row.innerBoxHeight">高：{{ scope.row.innerBoxHeight }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="innerBoxWeight" label="鞋盒毛重（kg）" align="center" width="130" />

      <el-table-column prop="specialPackingMethod" label="特殊包装方式" align="center" width="110" />

      <el-table-column prop="packageVersionId" label="包装版本ID" align="center" width="100" />

    </el-table>
    <el-row class="margintop" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </div>
</template>
<script>
import { vieworderId, purchaseorder } from '@/api/scm-api'
import { companyList } from '@/api/listSelection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

export default {

  mixins: [commodityInfoDict],
  data() {
    return {
      showLabel: false,
      DetailsDatas: [],
      tabledatas: [],
      selectLoading: false,
      buyerCompany: '',
      orderForm: {
        createTime: '',
        purchaseOrderTagList: []
      },
      rules: {
        buyerCompany: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        sellerCompany: [{ required: true, message: this.$t('page.required'), trigger: 'change' }]
      },
      isRemark: false
    }
  },
  computed: {

  },

  mounted() {
    this._vieworderId()
    this._companyList()
  },
  methods: {
    cancle() {
      this.$router.push({
        name: 'PurchaseOrder'
      })
    },
    // 获取公司信息数据
    async _companyList() {
      const { datas } = await companyList()
      this.DetailsDatas = datas
      this.DetailsDatas.map(e => {
        if (e.id === 1) {
          this.buyerCompany = e.companyName
        }
      })
    },
    handleChangeCode(val) {
      this.buyerCompany = val.companyName
    },
    async _vieworderId(query) {
      const { id } = this.$route.query
      if (id) {
        const { datas } = await vieworderId(id)
        this.orderForm = datas
        this.orderForm.createTime = this.orderForm.createTime.split(' ')[0]
        this.orderForm.purchaseOrderTagList = datas.purchaseOrderTagList ? datas.purchaseOrderTagList.map(item => item.tagDict) : []
        this.getTag()
        this.tabledatas = datas.detailList
        if (this.tabledatas.some(item => item.changeAfterHaveTaxPrice || item.changeTotalPrice)) {
          this.showLabel = true
        }
        // 获取最多的一条数据
        for (let i = 0; i < this.tabledatas.length; i++) {
          const list = JSON.parse(this.tabledatas[i].skuSpecificationJson)
          const myList = []
          list.map(e => {
            myList.push({ name: e.display.name, value: e.value.value })
          })
          this.tabledatas[i].myList = myList
        }
      }
    },
    // 保存采购计划
    save() {
      // 校验必填？？/未做
      this.$refs['orderForm'].validate((valid) => {
        if (valid) {
          const vo = {}
          vo.purchaseOrderCode = this.orderForm.purchaseOrderCode
          vo.contractCode = this.orderForm.contractCode
          vo.totalNumber = this.orderForm.totalNumber
          vo.vendorDeliveryFeedback = this.orderForm.vendorDeliveryFeedback
          vo.predictDeliveryDate = this.orderForm.predictDeliveryDate
          vo.purchaseTotalPrice = this.orderForm.purchaseTotalPrice
          vo.createTime = this.orderForm.createTime
          vo.createByName = this.orderForm.createByName
          vo.remark = this.orderForm.remark
          vo.buyerUser = this.orderForm.buyerUser
          vo.buyerPhone = this.orderForm.buyerPhone
          vo.sellerCompany = this.orderForm.sellerCompany
          vo.sellerPhone = this.orderForm.sellerPhone
          vo.sellerUser = this.orderForm.sellerUser
          vo.buyerCompany = this.buyerCompany
          // 新增订单标签
          vo.purchaseOrderTagList = this.orderForm.purchaseOrderTagList.map(item => Object.assign({}, { tagDict: item }))
          const { id } = this.$route.query
          if (id) {
            vo.id = id
            this._purchaseorder(vo)
          }
        } else {
          return
        }
      })
    },
    // 保存
    async _purchaseorder(vo) {
      try {
        this.Loadingsave = true
        const { code, msg } = await purchaseorder(vo)

        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.$router.push({
            path: '../',
            append: true
          })
          this.Loadingsave = false
        }
      } finally {
        this.Loadingsave = false
      }
    },
    // 订单标签为其他时，备注必填
    getTag() {
      this.isRemark = this.orderForm.purchaseOrderTagList.some(item => item === 'OTHER')
    }
  }
}
</script>
<style lang="scss">
// .el-table__body-wrapper::-webkit-scrollbar {
//   width: 4px;
// }
// .el-table__body-wrapper::-webkit-scrollbar-thumb {
//   border-radius: 2px;
//   height: 50px;
//   background: #D5D5D5;
// }
// .el-table__body-wrapper::-webkit-scrollbar-track {
//   border-radius: 2px;
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
//   background: rgb(245,245,245);
// }
</style>
<style lang="scss" scope>
.bg-purple-dark {
  border: 1px solid #bae7ff;
  background: #e6f7ff;
  color: #1890ff;
  size: 14px;
  padding: 6px;
  margin-bottom: 10px;
  .bg-purple-title {
    line-height: 36px;
    text-indent: 20px;
  }
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.full-width {
  width: 100%;
}
.el-date-editor.el-input.full-width {
  width: 100%;
}
</style>
